<script>
import { defineComponent, toRefs, ref } from 'vue';

import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import Toggle from '@/components/common/Toggle.vue';
import InputSlider from '@/components/common/InputSlider.vue';
import InputNumber from '@/components/common/InputNumber.vue';

const MIN_PERCENTAGE = 15;
const MAX_PERCENTAGE = 70;

const PremiumOfferModal = defineComponent({
  components: {
    Modal,
    Button,
    Toggle,
    InputSlider,
    InputNumber,
  },
  emits: ['close', 'update'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    usePremium: {
      type: Boolean,
      required: true,
    },
    basePercentage: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      usePremium,
      basePercentage,
    } = toRefs(props);

    const { t } = useI18n();

    const companyUsesPremium = ref(usePremium.value);
    const catalogBasePercentage = ref(basePercentage.value);

    const handleUpdate = () => {
      emit('update', {
        usePremium: companyUsesPremium.value,
        basePercentage: catalogBasePercentage.value,
      });
    };

    return {
      t,
      companyUsesPremium,
      catalogBasePercentage,
      handleUpdate,
    };
  },
  render() {
    return (
      <Modal isDisplayed={this.visible} onCloseModal={() => this.$emit('close')}>
        <h2 class="mb-2">{this.t('pages.offers.features.premium.title')}</h2>
        <p innerHTML={this.t('pages.offers.features.premium.description')} />

        <Toggle
          class="my-1"
          vModel={this.companyUsesPremium}
          leftText={this.t('components.premiumOfferModal.no')}
          rightText={this.t('components.premiumOfferModal.yes')}
        />

        {this.companyUsesPremium && (
          <>
            <p class="mt-2 mb-1">{this.t('components.premiumOfferModal.baseCatalogPercentage')}</p>
            <div class={this.$style.percentageWrapper}>
              <InputSlider
                className={this.$style.percentageSlider}
                vModel={this.catalogBasePercentage}
                minValue={MIN_PERCENTAGE}
                maxValue={MAX_PERCENTAGE}
              />
              <InputNumber
                className={this.$style.percentageNumber}
                vModel={this.catalogBasePercentage}
                minValue={MIN_PERCENTAGE}
                maxValue={MAX_PERCENTAGE}
              />
            </div>
          </>
        )}

        <Button class="mt-2 tar" onButtonClick={this.handleUpdate}>
          {this.t('components.premiumOfferModal.confirm')}
        </Button>
      </Modal>
    );
  },
});

export default PremiumOfferModal;
</script>

<style lang="scss" module>
@import "@/assets/styles/common/variables";
@import "@/assets/styles/common/mixins";

.percentageWrapper {
  display: flex;
  align-items: center;
}

.percentageNumber {
  width: calc(20% - 0.5rem);
}

.percentageSlider {
  width: calc(80% - 0.5rem) !important;
  margin-right: 2rem;
  margin-left: 0.8rem;
}
</style>
