<script>
import { defineComponent } from 'vue';

import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';

const LoyaltyOfferModal = defineComponent({
  components: {
    Modal,
    Button,
  },
  emits: ['close'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  render() {
    return (
      <Modal isDisplayed={this.visible} onCloseModal={() => this.$emit('close')}>
      <h2 class="mb-2">{this.t('pages.offers.features.loyaltyCard.title')}</h2>

      <p>{this.t('pages.offers.features.loyaltyCard.description')}</p>
    </Modal >
    );
  },
});

export default LoyaltyOfferModal;
</script>
