<script>
import { defineComponent, toRefs } from 'vue';

import numberUtils from '@/utils/number.utils';

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    minValue: {
      type: Number,
      required: false,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: false,
      default: 100,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    interval: {
      type: Number,
      required: false,
      default: 1,
    },
    className: {
      type: [String, Object],
      required: false,
    },
  },
  setup(props, { emit }) {
    const {
      minValue,
      maxValue,
    } = toRefs(props);

    const handleChange = (event) => {
      const value = parseInt(event.target.value, 10);

      emit('update:modelValue', numberUtils.restrictedNumber(value, minValue.value, maxValue.value));
    };

    return {
      handleChange,
    };
  },
  render() {
    return (
      <input
        type="number"
        class={this.className}
        value={this.modelValue}
        min={this.minValue}
        max={this.maxValue}
        onChange={this.handleChange}
      />
    );
  },
});
</script>
