<template>
  <Layout :loading="loading">
    <h1 class="mb-1 tac">{{ t('pages.offers.title') }}</h1>

    <p class="mb-2 tac">{{ t('pages.offers.description') }}</p>

    <div :class="$style.offerTiles">
      <Tile
        v-for="feature of features"
        :key="feature.key"
        :class="$style.offer"
        :active="feature.active"
        @click="setShownInfo(feature.key)"
      >
        <h2
          :class="['mb-2', $style.offerTitle]"
        >{{ t(`pages.offers.features.${feature.key}.title`) }}</h2>
        <p
          :class="$style.offerDescription"
        >{{ t(`pages.offers.features.${feature.key}.additionalText`) }}</p>
      </Tile>
    </div>

    <template v-if="company">
      <PremiumOfferModal
        :visible="showPremiumInfo"
        :usePremium="company.usePremium"
        :basePercentage="company.basePercentage"
        @close="closeModal"
        @update="updatePremiumOffer"
      />
      <LoyaltyOfferModal :visible="showLoyaltyCardInfo" @close="closeModal" />
    </template>
  </Layout>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import Layout from '@/components/common/Layout.vue';
import Tile from '@/components/common/Tile.vue';
import PremiumOfferModal from '@/components/offers/PremiumOfferModal.vue';
import LoyaltyOfferModal from '@/components/offers/LoyaltyOfferModal.vue';

import useCurrentCompany from '@/composables/useCurrentCompany';

import lowcoApi from '@/api/lowco-api';

const PREMIUM = 'premium';
const LOYALTY_CARD = 'loyaltyCard';

export default defineComponent({
  name: 'Offers',
  components: {
    PremiumOfferModal,
    LoyaltyOfferModal,
    Layout,
    Tile,
  },
  setup() {
    const { t } = useI18n();
    const { currentCompanyId } = useCurrentCompany();

    const loading = ref(false);
    const company = ref(null);
    const activeModal = ref(null);

    const features = [
      {
        key: PREMIUM,
        active: true,
      },
      {
        key: LOYALTY_CARD,
        active: false,
      },
    ];

    const showPremiumInfo = computed(() => activeModal.value === PREMIUM);
    const showLoyaltyCardInfo = computed(() => activeModal.value === LOYALTY_CARD);

    watch(currentCompanyId, (value, oldValue) => {
      if (value === oldValue) {
        return;
      }

      const fetchCompany = async (companyId) => {
        try {
          loading.value = true;
          company.value = await lowcoApi.getMyEnterprise(companyId);
        } catch (err) {
          // TODO handle error
        } finally {
          loading.value = false;
        }
      };

      fetchCompany(value);
    }, { immediate: true });

    const updatePremiumOffer = (newOffer) => {
      // TODO API call
      console.log(newOffer);
    };

    const setShownInfo = (key) => {
      if (activeModal.value === key) {
        return;
      }

      activeModal.value = key;
    };

    const closeModal = () => {
      activeModal.value = null;
    };

    return {
      t,
      loading,
      company,
      features,
      showPremiumInfo,
      showLoyaltyCardInfo,
      updatePremiumOffer,
      setShownInfo,
      closeModal,
    };
  },
});
</script>

<style lang="scss" module>
@import "@/assets/styles/common/variables.scss";
@import "@/assets/styles/common/mixins.scss";

.offerTiles {
  padding: 2rem;
  @include spacing-children("vertical", 0);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  place-content: center;

  gap: 2rem;
}

.offer {
  width: 100%;
}

.offerTitle,
.offerDescription {
  color: inherit;
}
</style>
